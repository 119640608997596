@import "globals";

.sa-text-table__container {
  width: 100%;
  min-height: 200px;
  max-height: 500px;
  overflow: auto;
}

.sa-text-table {
  width: 100%;
  font-family: $font-family;
  font-size: $root-font-size;
  color: $text-color;
  border-width: 1px;
	border-collapse: collapse;
  background-color: $background-color;
}

.sa-text-table__cell {
	border-width: 1px;
	padding: 8px;
	border-style: solid;
	border-color: $border-color;
  background-color: $background-color;
  min-height: $form-element-height;
}
