@import "../variables.scss";
.sd-multipletext {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  height: 1px;
}
.sd-multipletext__cell {
  height: 100%;
}
.sd-multipletext__cell:not(:last-child) {
  padding-right: calcSize(2);
}
.sd-multipletext__item-container.sd-input:focus-within {
  box-shadow: 0 0 0 2px $primary;
}
.sd-multipletext__item-container {
  display: flex;
  align-items: flex-start;
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  .sd-input {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    border: none;
    box-shadow: none;
  }
}
.sd-multipletext__item-container .sd-input,
.sd-multipletext__item-title {
  margin-top: calcSize(1.5);
  margin-bottom: calcSize(1.5);
}
.sd-multipletext__item-title {
  height: calc(100% - #{$base-unit} * 3);
  max-width: 30%;
  padding-right: calcSize(2);
  border-right: 1px solid $border;
  word-break: break-word;
  white-space: normal;
  color: $foreground-light;
}
.sd-multipletext__item {
  flex-grow: 1;
}
.sd-multipletext__content .sd-multipletext__item-container {
  position: relative;
}
.sd-multipletext__item-container--error {
  background-color: $red-light;
  .sd-input--error {
    background-color: transparent;
  }
}
.sd-multipletext__item-container:hover:not(:focus-within) > .sd-question__erbox--tooltip {
  display: inline-block;
}
.sd-multipletext tr:not(:last-child) .sd-multipletext__cell {
  padding-bottom: calcSize(2);
}
.sd-multipletext .sd-input .sd-input {
  background: transparent;
}
